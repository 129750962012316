import React from "react";

function TermsOfService() {
  return (
    <>
      <style>
        {`body {
          overflow: hidden;
          background: transparent;
        }`}
      </style>
      <div style={{color: '#fff', whiteSpace: 'pre-wrap'}}>
        <p>
          {String.raw`Terms of Service

Last updated: November 14, 2023

1. BACKGROUND; APPLICABILITY.

1.1. Background. Helios Sports, Inc. (“HELIOS”, “we”, “us”, “our”) provides the proprietary HELIOS® platform (the “Platform”) for use with the wearable HELIOS CoreTM performance sensor (the “Device”) and HELIOS mobile app (the “App”). In these Terms, the Platform, the Device and the App may sometimes be referred to collectively as the “Service,” although each may be referred to individually in the applicable context The Service uses player and team performance data captured by the Device and uploaded to a cloud data lake that is then analyzed by the Platform using, among other things, artificial intelligence and machine learning to objectively rate performance. The Service is provided on a subscription-based membership licensing model and delivers video highlights, performance benchmarking and gamified training experiences to players, coaches and trainers through App. 

1.2. Applicability. These Terms of Service (these “Terms”) constitute a legal agreement between HELIOS and the individual and/or organization (“you”, “your”) using the Service or authorizing the use of the Service by a minor. By clicking on the “I ACCEPT” or similar button in the App, or otherwise using the Service, you are accepting and agreeing to these Terms, as well as the terms and conditions of our Privacy Policy found at https://helioshockey.com/privacy and incorporated into these Terms by this reference. 

1.2.1. Use by Minors. You must be at least 13 years old to use the Service; however, children of all ages may use the Service if enabled by a parent or legal guardian. If you are under 18 (or under the legal age of majority in your jurisdiction), you represent and warrant to us that you have permission from your parent or guardian to use the Service.
If you are a parent or legal guardian of a user under 18, by allowing your child to use the Service, you are subject to these Terms and are responsible for your child’s use of the Service, including any violation of these Terms by your child.
We may, in our sole discretion and without liability, (a) refuse to offer to or (b) terminate access to the Service by anyone we suspect of falsifying age or consent information or of circumventing our age and consent verification procedures. Your license to use the Service is revoked where these Terms are prohibited by, or to the extent the offering, sale or provision of the Service conflicts with, any applicable law, rule or regulation.

1.2.2. Use by an Organization. If you use the Service on behalf of an organization, such as a team, school, league or other entity, then you agree to these Terms on behalf of that organization, and you represent to us that you have the authority to do so. If you do not agree to these Terms, then you must not use the Service.

2. SUBSCRIPTION MEMBERSHIP TERMS.

2.1. General. In order to use the Service, you must have a Device and download the App, where you will be asked to agree to subscribe as a member, pay any applicable subscription fees (“Membership Fees”) when due, and create an account (“Account”). You must maintain an active membership to continue using the Service. You agree to provide true, accurate and complete information and keep your Account information current.  You will at all times have the ability to delete your Account, either directly or through a request made to one of our employees or affiliates. If you do so, however, you will not be able to use the Service, and we will continue to charge you in accordance with these Terms. Current Membership Fees are found at https://helioshockey.com and are payable in advance for monthly and annual subscriptions as further described below. We may change the Membership Fees and subscription structure from time to time in our discretion. Your membership will automatically renew at the end of your subscription period unless you terminate your subscription or, in some cases, change your subscription level.

2.2. Membership Fees.

2.2.1. Introductory Offers. Where applicable, your purchase of a membership subscription and/or your commitment to a minimum membership term may entitle you to the use of the Services (including the Device) for the period indicated to you at the time of purchase, depending on the type of membership you choose at that time.
For example, if we offer you a Device with an introductory PRO Annual membership for one annual Membership Fee, then we will charge your Payment Method (defined below) the annual Membership Fee in advance and provide the Device to you, which you may keep for free, even if you do not renew after the first year. If we offer you a Device with an introductory PRO Monthly membership for a minimum membership term, such as three (3) months, then we will charge your Payment Method the monthly Membership Fee in advance and provide the Device to you. If you cancel your introductory PRO Monthly membership after the initial minimum membership term and return the device to us in accordance with our returns policy, we will discontinue charging you the monthly Membership Fee. If you wish to cancel your introductory PRO Monthly membership but keep the Device, we will continue to charge your Payment Method the monthly Membership Fee until you have paid for twelve (12) consecutive months of Service.

2.2.2. Cancellations Within 30 Days. As always, you may cancel the Service and return the Device to us in accordance with our returns policy within the first thirty (30) days of your initial membership. If you do so and have a PRO Annual membership, we will refund your annual Membership Fee, less the first month’s service (i.e., we will retain 1/12 of the annual Membership Fee); if you do so and have a PRO Monthly membership, we will excuse you from the remainder of the applicable minimum PRO Monthly membership term.

2.2.3. Payment Obligation. By choosing to subscribe to the Service, you agree to pay us, either directly or through a third party payment processor (“Payment Processor”), all Membership Fees and other charges at the prices then in effect and in accordance with the applicable payment terms, by authorizing us, directly or through the Payment Processor, to charge your chosen payment provider for those Membership Fees and other charges (your “Payment Method”). You agree to make payment using your selected Payment Method. We reserve the right to correct any errors or mistakes that we or a Payment Processor make even if we or a Payment Processor have already requested or received payment. The processing of payments by a Payment Processor will be subject to the terms, conditions and privacy policies of such Payment Processor in addition to these Terms. We are not responsible for any errors by a Payment Processor.

2.2.4. Payment Method. The terms of your payment will be based on your Payment Method and may be determined by agreements between you and the financial institution, credit card issuer or other provider of your chosen Payment Method. If we, through a Payment Processor, do not receive payment from you, you agree to pay all amounts due on your Account upon demand.

2.2.5. Recurring Billing. Membership payment terms are in advance (either monthly or annually, as applicable) and your membership subscription automatically renews at the end of each applicable period (i.e., monthly or annually). For PRO Monthly memberships, the Service Period begins when you sign up for the Service. For PRO Annual memberships, the Service period begins when you complete your first session wearing the Device. You acknowledge that Membership Fees have a recurring payment feature and you accept responsibility for all recurring charges prior to cancellation.  WE MAY SUBMIT MONTHLY OR ANNUAL CHARGES, AS APPLICABLE TO YOUR TYPE OF MEMBERSHIP, WITHOUT FURTHER AUTHORIZATION FROM YOU, SUBJECT TO CANCELLATION IN ACCORDANCE WITH THESE TERMS. CANCELLATION NOTICES WILL NOT AFFECT CHARGES SUBMITTED BEFORE WE REASONABLY COULD ACT OR CHARGES YOU HAVE AGREED UNDER THESE TERMS TO PAY EVEN IF YOU CANCEL. TO TERMINATE YOUR SUBSCRIPTION OR TO CHANGE YOUR PAYMENT METHOD, PLEASE CONTACT US AT support@helioshockey.com. WE DO NOT CHARGE OR ISSUE REFUNDS FOR PARTIAL PERIODS, EXCEPT AS OTHERWISE STATED IN THESE TERMS OR EXPRESSLY AGREED IN WRITING. PARTIAL CANCELLATION IS SUBJECT TO TERMS AND CONDITIONS RELATING TO MINIMUM COMMTITMENTS AND/OR THE RETURN OF DEVICES AS SET FORTH IN THESE TERMS OR AS OTHERWISE AGREED BETWEEN YOU AND US IN WRITING.

2.2.6. Current Information Required. YOU MUST PROVIDE CURRENT, COMPLETE AND ACCURATE INFORMATION FOR YOUR ACCOUNT. YOU MUST PROMPTLY UPDATE ALL INFORMATION TO KEEP YOUR ACCOUNT CURRENT, COMPLETE AND ACCURATE (SUCH AS A CHANGE IN BILLING ADDRESS, CREDIT CARD NUMBER, OR CREDIT CARD EXPIRATION DATE), AND YOU MUST PROMPTLY NOTIFY US OR OUR PAYMENT PROCESSOR IF YOUR PAYMENT METHOD IS CANCELED (E.G., FOR LOSS OR THEFT) OR IF YOU BECOME AWARE OF A POTENTIAL BREACH OF SECURITY, SUCH AS THE UNAUTHORIZED DISCLOSURE OR USE OF YOUR USER NAME OR PASSWORD. CHANGES TO YOUR ACCOUNT INFORMATION CAN BE MADE BY CONTACTING US AT support@helioshockey.com.

3. LICENSE.

3.1. Personal Use License. Under these Terms, we grant you a limited, revocable, non-exclusive, non-transferable, non-sublicensable license to access and use the Service for your personal use so long as your subscription membership is in effect. If your use of the Service is on behalf of a team, league or other organization, you may also use the Service for the individual or collective benefit of participants in your organization who are also active subscribers to the Service, subject to the tools and other functionality made available to you through the Service and to consent procedures implemented through or in connection with the Service or requirements of these Terms or applicable law.

3.2. Your Obligations; In General. To the fullest extent permitted by applicable law, you agree that:

3.2.1. You will not disclose or provide the Service (or any modifications or derivatives of all or any part thereof), or access to or use of the Service or any element thereof, to any other party;

3.2.2. You will not alter, modify, adapt, translate or create derivative works of the Service or any element thereof;

3.2.3. You will not reverse engineer, disassemble, decompile or otherwise attempt to discover the source code or any underlying algorithms of the Service or any element thereof;

3.2.4. You will take all reasonable precautions to prevent unauthorized or improper use of your login credentials;

3.2.5. You will provide us with feedback about the Service and how it operates as well as your question and comments by submitting such feedback via the Service. All such feedback upon disclosure to us becomes our property, which you agree to treat as confidential and you agree not disclose or use such feedback without our express prior written consent; 

3.2.6. You will not remove any copyright and other proprietary notices contained in the Service;

3.2.7. You will not use the Service:

• For illegal purposes, or in support of illegal activities;

• To harm, or attempt to harm, minors in any way, including, but not limited to the posting, transmission, or receipt of child pornography;

• To make offers, whether fraudulent or otherwise, to sell or buy products, items, or services or to advance any type of commercial enterprise or financial scheme, including without limitation pyramid schemes, Ponzi schemes, or chain letters;

• To deceive, mislead, impersonate or attempt to deceive, mislead or impersonate any person, including without limitation by adding, removing or modifying identifying network header information, using forged headers or other identifying information (your use of anonymous remailers or nicknames, if otherwise in compliance with these Terms, does not constitute impersonation);

• To access, or to attempt to access, the Accounts of others, or to penetrate, or attempt to penetrate, our security measures or another person’s or entity’s computer software or hardware, electronic communications system, or telecommunications system, whether or not the intrusion results in the corruption or loss of data or installation of malicious code or other materials;

• To collect, or to attempt to collect, personal information about any third party without such party’s express knowledge and consent;

• For or in connection with any activity that affects or interferes with the ability of other people or systems to use the Service, our ability to provide the Service, our networks and systems in general, or the Internet itself, including without limitation “denial of service” (DOS) attacks against another network host or individual user as well as interference with, unauthorized access to, or other violation of the security of our (or another party's) server, network, network access, personal computer or control devices, software or data, or other system, and attempts to do any of the foregoing; or

• To intentionally distribute software that attempts to and/or causes damage, harassment, or annoyance to persons, data, and/or computer systems; and

3.2.8. You are responsible for taking prompt corrective action(s) to remedy any violation of these Terms by you and to help prevent similar future violations.

3.3. Your Obligations: Security.  You agree that:

3.3.1. You will not select or use as a username a name of another person with the intent to impersonate that person or use as a username a name subject to any rights of any other person without appropriate authorization, which authorization you agree to provide to us upon request;

3.3.2. You are responsible for ensuring and maintaining the security of your Account, including your user name, password, and other log-in credentials, systems, machines and devices that connect to and use the Service, including implementation of any necessary patches and operating system updates and you are and will be held responsible and accountable for any activity using your Account; 

3.3.3. You will not obtain, or attempt to obtain, another user’s Account user name and password, or other log-in credentials, share your Account, user name, password, or other log-in credentials with others, or post or publish your Account credentials; and

3.3.4. You will notify us immediately of any change in your eligibility to use the Service, breach of security or unauthorized use of your Account or password. 

3.4. Your Obligations: Content.  You agree that:

3.4.1. You will not transmit any Content (by e-mail, uploading, posting or otherwise) that violates these Terms, where “Content” means any and all forms of communications, by any means and media now known or hereafter developed, including without limitation communications containing comments, reviews, posts, feedback, questions, answers, notes, messages, suggestions, narrative descriptions, text, graphics (including photographs, illustrations, images, drawings, and logos), executable programs, video recordings, and audio recordings, whether created or provided by you or others; 

3.4.2. You will not use the Service:

• To harass any person or entity, whether by your posting or transmission of any harassing Content or otherwise);

• To post or transmit any Content that infringes any copyright, trademark, patent, trade secret or other proprietary rights of any third party, including, but not limited to, the unauthorized copying of copyrighted material, the digitization and distribution of photographs from magazines, books, or other copyrighted sources, and the unauthorized transmittal of copyrighted software; or

• To post or transmit Content that we determine, at our sole discretion, to be unlawful, indecent, or objectionable;

3.4.3. You are and remain solely and fully responsible for any Content that you post, host, download/upload, create, access or transmit using the Service.  

You agree to defend, indemnify, and hold us and our subsidiaries, affiliates, officers, agents, suppliers, employees, partners, licensors and subscribers harmless from and against any claims, actions or demands, including, without limitation, reasonable legal and expert fees, arising or resulting from (i) your Content, (ii) your breach of these Terms, or (iii) your violation of law. We shall provide notice to you of any such claim, suit, or proceeding and shall assist you, at your expense, in defending any such claim, suit or proceeding. We reserve the right to assume the exclusive defense and control of any matter that is subject to this section. In such case, you agree to cooperate with any reasonable requests assisting our defense of such matter.

4. OUR RIGHTS. We reserve the right to suspend your use of and access to the Service, terminate your license and subscription membership and deactivate or delete your Account without notice upon your violation of these Terms. We also may block or remove any Content in our sole and absolute discretion. We may cooperate with legal authorities and/or third parties in the investigation of any suspected or alleged crime or civil wrongdoing. 

5. SUPPORT. Service support may be obtained by sending an email to support@helioshockey.com. We will strive to supply support in a commercially reasonable time.

6. OWNERSHIP. You acknowledge that:

6.1. All right, title and interest in and to the Service and all intellectual property rights associated with the Service are and will remain with us and/or our licensors;

6.2. These Terms conveys no right or interest in the Service other than a limited license to access and use the Service in accordance with these Terms;

6.3. The Service and its related website are protected by copyright and other intellectual property laws throughout the world; and

6.4. HELIOS embodies our valuable proprietary and confidential information.

7. DISCLAIMERS. EXCEPT AS OTHERWISE EXPRESSLY SET FORTH IN THESE TERMS, AND TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, WE PROVIDE THE SERVICE "AS IS" AND “AS AVAILABLE” WITHOUT ANY WARRANTIES, WHETHER EXPRESS OR IMPLIED, OR ARISING BY OPERATION OF LAW OR OTHERWISE, RELATING TO THE SERVICE. WE SPECIFICALLY DISCLAIM ALL IMPLIED WARRANTIES, INCLUDING WITHOUT LIMITATION THOSE OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. YOUR ACCESS TO AND USE OF THE SERVICE MAY BE SUBJECT TO LIMITATIONS, DELAYS, AND OTHER PROBLEMS INHERENT IN THE USE OF THE INTERNET AND ELECTRONIC, WIRELESS, AND CELLULAR COMMUNICATIONS, FOR WHICH WE ARE NOT RESPONSIBLE. WE HAVE NO AND DISCLAIM ANY RESPONSIBILITY FOR ANY CONTENT CREATED OR POSTED BY YOU OR OUR OTHER USERS, INCLUDING ANY CONTENT PROVIDED ON ANY THIRD-PARTY WEBSITES LINKED TO THE SERVICE. SUCH THIRD-PARTY WEBSITE LINKS ARE PROVIDED AS INTERNET NAVIGATION TOOLS FOR INFORMATIONAL PURPOSES ONLY AND DO NOT CONSTITUTE IN ANY WAY AN ENDORSEMENT BY US OF THE CONTENT(S) OF SUCH SITES.

8. LIMITATION OF LIABILITY. IN NO EVENT WILL WE (OR OUR LICENSORS OR SUPPLIERS): (i) HAVE A MAXIMUM AGGREGATE LIABILITY TO YOU FOR DIRECT DAMAGES CAUSED BY US THAT EXCEEDS YOUR SUBSCRIPTION FEE IN THE AGGREGATE; OR (ii) BE LIABLE FOR ANY INCIDENTAL, CONSEQUENTIAL, PUNITIVE, EXEMPLARY, SPECIAL OR INDIRECT DAMAGES (INCLUDING BUT NOT LIMITED TO LOST BUSINESS PROFITS, LOST REVENUES AND LOSS, REVENUES, DAMAGE OR DESTRUCTION OF DATA) EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THESE LIMITATIONS WILL APPLY EVEN IF A REMEDY SET FORTH HEREIN IS FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE.  THE PARTIES INTEND THAT THIS SECTION BE ENFORCED TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW.

9. TERM AND TERMINATION.

9.1. General. These Terms will become effective when accepted or when you first use the Service, whichever is earlier, and will terminate upon the cancellation of your membership, whether by you or by us. We may also terminate these Terms and your license and subscription membership at our convenience should we cease to make the Service available commercially.

9.2. Effect of Termination. Upon any termination of these Terms, you must immediately cease use of the Service, and we will disable your login credentials and delete your Account. Sections 1.2, 2.2, 6 through 11, and your obligations to defend and indemnify us will survive termination of these Terms.

10. COPYRIGHT COMPLAINTS.  If you believe that your Content has been copied and posted, stored or transmitted using the Service in a way that constitutes copyright infringement, please submit a notification pursuant to the Digital Millennium Copyright Act ("DMCA") in accordance with the process detailed below and directed to the designated agent listed below. To be effective, the notification must be a written communication that includes the following: 

• A physical or electronic signature of a person authorized to act on behalf of the owner of an exclusive right that is allegedly infringed; 

• Identification of the copyrighted work claimed to have been infringed, or, if multiple copyrighted works at a single online Site are covered by a single notification, a representative list of such works at that Site; 

• Identification of the material that is claimed to be infringing or to be the subject of infringing activity and that is to be removed or access to which is to be disabled, and information reasonably sufficient to permit us to locate the material; 

• Information reasonably sufficient to permit us to contact the complaining party, such as an address, telephone number and, if available, an electronic mail address at which the complaining party may be contacted; 

• A statement that the complaining party has a good-faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent or the law; and 

• A statement that the information in the notification is accurate, and under penalty of perjury, that the complaining party is authorized to act on behalf of the owner of an exclusive right that is allegedly infringed. 

We have designated a Copyright Agent for notice of claims of copyright or trademark infringement. Please send your complaint to us in writing at:

Helios Sports, Inc.
Attn: DCMA Agent
348 Maplewood Ave, Unit 5
Portsmouth, NH 03801

Or via email to:

dmcaagent@helioshockey.com

In accordance with the DMCA and other applicable law, we have adopted a policy of terminating, in appropriate circumstances and in our sole discretion, accounts of those users who are deemed to be repeat infringers. We may also, in our sole discretion, limit access to the Service and/or terminate the accounts of users who infringe the intellectual property rights of others, whether or not there is any repeat infringement. 

11. MISCELLANEOUS. You may not assign or otherwise transfer your license, your subscription membership, or any of your rights or obligations hereunder without our prior written consent. These Terms will be governed by, and construed in accordance with, the laws of the State of New Hampshire (U.S.A.) without regard to its conflict of laws principles. You agree that any action at law or in equity arising out of or relating to these Terms will be filed only in the state and federal courts located in New Hampshire and hereby irrevocably and unconditionally consent and submit to the exclusive jurisdiction of such courts over any such suit, action or proceeding. To the extent that any provision of these Terms is found by a court of competent jurisdiction to be void or unenforceable, such provision will be without effect and the remainder of these Terms will be enforced to the full extent of the law. No action arising out of or related to these Terms may be brought more than six (6) months after the termination of your license. In no event will we be liable for any delay or failure to perform under these Terms which is due to causes beyond our reasonable control. These Terms, including the incorporated references to our Privacy Policy, constitute the complete agreement between you and us and supersede all prior or contemporaneous agreements or representations, written or oral, concerning the subject matter of these Terms. These Terms may not be modified or amended except in writing signed by a duly authorized representative of each of us; no other act, document, usage or custom will be deemed to amend or modify these Terms.
 

YOU ACKNOWLEDGE THAT YOU HAVE READ THESE TERMS, UNDERSTAND THEM, AND AGREE TO BE BOUND BY THEM.`}
        </p>
      </div>
    </>
  );
}

export default TermsOfService;
