import React from "react";

function PrivacyPolicy() {
  return (
    <>
      <style>
        {`body {
          overflow: hidden;
          background: transparent;
        }`}
      </style>
      <div style={{color: '#fff', whiteSpace: 'pre-wrap'}}>
        <p>
          {String.raw`Privacy Policy

Last updated: January 27, 2022

Helios Sports, Inc. (“HELIOS”, “we”, “us”, or “our”) is on a mission to help athletes get better, faster. To be successful we collect information that allows us to deliver a world-class experience to you. We take your privacy seriously and want you to understand how we use, collect, share and protect your personal information.

Privacy Policy Coverage

This Privacy Policy applies to personal information we collect about our members and other consumers who interact with HELIOS or use our services, including by visiting our websites, or by visiting our social media pages, or by using our mobile apps, or by using the HELIOS™ Core™ device (collectively, the “Services”). It does not cover third-party sites and services that we do not own or control, or people that we do not manage. We are not responsible for the policies and practices of any third-parties, and we do not control, operate, or endorse any information, products, or services that may be offered by third parties or accessible on or through the Services.

Changes to This Privacy Policy

Helios constantly improves its Services. We may update this Privacy Policy from time-to-time by posting a new version online. We will provide notice of any material changes by sending you an email, posting the changes on or through the Services, or posting an update on our website. Continued use of the Services following any change or revision of this Privacy Policy constitutes acceptance of any change or revision.

Information We Collect

We may collect the following types of personal information:

Personal identifiers: for example, your first and last name, email address, mailing address, phone number, the username and password that you use to create a HELIOS account, as well as any photos or information you choose to include in your HELIOS profile.

Communications: for example emails, mobile app messages or notifications we exchange with you.

Performance data: for example, data collected from Services (such as stride speed, stride load, explosiveness, and active time) that represents your athletic skill or capabilities. We may use this information to personalize your experience as part of our Services.

Health data: for example, your heart rate.

Billing information: for example, your name, email address, and billing information that pertains to the type of products and Services you have purchased from us. We do not collect or store your financial information, such as your credit card number or bank account number used to complete your transactions for Services – that information is collected by our payment processor(s) or, in the case of obtaining parental consent (discussed below), by our verification partners. 

Marketing data: for example, your preferences for marketing communications, and details about your engagement with them (e.g. marketing emails you open, time spent viewing and the links you click within them).

Device data: for example, your mobile device or computer system information (e.g. manufacturer, model, operating system, memory size, device type, IP address, unique identifiers, language settings, mobile device carrier, radio/network information, and general location information).

Geolocation data: for example, your IP address, MAC address, hardware model, network location or physical GPS location.

Online activity data: for example, websites or mobile app screens you visited, how long you spent on a website or mobile app screen, navigation between websites or mobile app screens, information about your activity on a website or mobile app screen. 
Photographic and videographic data: for example, images and video of you and others when you are using the HELIOS Core device and application.

Automatic Collection

We, our service providers, and our third-party partners may automatically collect information about you, your mobile device or computer, and your activity over time on the Services, including: 

Online activity data: for example, websites or mobile app screens you visited, how long you spent on a website or mobile app screen, navigation between websites or mobile app screens, information about your activity on a website or mobile app screen.

Performance data: for example, data collected from Services (such as stride speed, stride load, explosiveness, and active time) that represents your athletic skill or capabilities. We may use this information to personalize your experience as part of our Services.
  
Device data: for example, your mobile device or computer system information (e.g. manufacturer, model, operating system, memory size, device type, IP address, unique identifiers, language settings, mobile device carrier, radio/network information, and general location information).

Cookies and Tracking Technologies

We use various tracking technologies to collect information, and this may include sending cookies to you. Cookies are small data files stored on your hard drive or in device memory that help us to improve our Services and your experience, to see which areas and features of our Services are popular, and to count visits. Please note that if you set your browser to disable cookies, some of our Services may not work or function properly or with full functionality. 

We use the following types of cookies:

Essential: cookies required for access to our website and its core functionality.

Behavioral/Analytical: cookies used to gather behavioral information through our website, provide customized content and recommendations, and analytics.

Marketing/Retargeting: cookies that are used to deliver relevant advertising or track the source of referrals to our website.

Additionally we use the following tracking technologies to improve our Services:

Web beacons: also known as “pixel tags” or “clear GIFs” to track when some of our emails are accessed or opened or when certain content is viewed or clicked. The statistics from web beacons help us understand which marketing campaigns are successful and who is interested in our products or services; over time, this helps us calibrate and improve our results so we are sending the most interesting content to the most interested people.

Social media widgets: which are links to Facebook, Instagram, Twitter, YouTube and LinkedIn (that might include widgets, such as, the “share this” button or other interactive mini-programs). These features may collect your IP address and which page you are visiting on the website and may set a cookie to enable the feature to function properly. These social media features are either hosted by a third party or hosted directly on our website(s). Your interactions with these features are governed by the privacy policy of the company providing it.

Google Analytics: we also use Google Analytics to collect information regarding visitor behavior and demographics on some of our Services. This analytics data is not tied to any personal information other than location data. For more information about Google Analytics, please visit www.google.com/policies/privacy/partners/. You can opt-out of Google's collection and processing of data generated by your use of the Services by going to http://tools.google.com/dlpage/gaoptout.

Firebase: we also use Firebase, which is another analytics service provided by Google. For more information on what type of information Firebase collects, please visit https://policies.google.com/technologies/partner-sites. You may opt-out of certain Firebase features through your mobile device settings, such as your device advertising settings or by following the instructions provided by Google in their Privacy Policy here https://policies.google.com/privacy. We also encourage you to review the Google's policy for safeguarding your data here https://support.google.com/analytics/answer/6004245.

Other third-party platforms: When you choose to connect with a social media platform or other third-party platform when using any of the Services, including Facebook, Instagram, or Apple Health, we may collect information about you from that platform, including any information that you choose to import into the Services. You may also be able to access posting and sharing tools on the Services that allow you to post information to a social media or third-party platform. By using these tools, you acknowledge that some account information may be transmitted from the applicable platform account to us; our treatment of that information is covered by this Privacy Policy. Additionally, when you use one of these tools, the social media or third-party platform may be collecting information about your online activity through its own tracking technologies, subject to its own privacy policy. We encourage you to read the privacy and other policies of any third-party platform you use in connection with any of the Services.


Consent and Legitimate Interests in Processing

Certain cookie technologies are employed to make the Services function for their intended purposes. By choosing to use our Services after having been notified of our use of such cookie technologies in the ways described in this Privacy Policy, and, in applicable jurisdictions, through notice and unambiguous acknowledgement of your consent, you agree to such use.



Children and Privacy

HELIOS knows that athletes come in all ages, and many are children.  We are committed to protecting the privacy of children who use our Services. This section of our Privacy Policy explains our information collection, disclosure, and practices regarding the consent of a parent or guardian (either, a “parent”, as used in this Privacy Policy) with respect to information provided by children under the age of 13 (“child” or “children”, as used in this section), is in accordance with the U.S. Children's Online Privacy Protection Act (“COPPA”), and outlines our practices regarding children's personal information.
 
Collection of Information from Children and Involvement of a Parent or Guardian: Children can register with HELIOS to view and create content and participate in contests and challenges with other users, among other things. We therefore ask for birth dates from all users to validate their ages. In any instance where we ask for age and determine the user is a child under 13 years of age, we will ask for a parent's email address before we collect any personal information from the child. If you believe your child is participating in an activity that collects personal information and you or another parent have NOT received an email providing notice or seeking your consent, please feel free to contact us at privacy@helioshockey.com.
 
We will not use a parent's email address provided for consent purposes to market to that parent, unless that parent has expressly opted in to email marketing or has separately participated in an activity that allows for such email contact.

Please note that, as with adults, children can choose whether to share their information with us, but certain features cannot function without it. As a result, children may not be able to access certain features if required information has not been provided. We will not require a child to provide more information than is reasonably necessary in order to participate in an activity.

When we collect personal information from a child, we retain that information only so long as reasonably necessary to fulfill the activity request or allow the child to continue to participate in the activity and ensure the security of our users and our services, or as required by law. In the event we discover we have collected information from a child in a manner inconsistent with COPPA's requirements, we will either delete the information or immediately seek the parent's consent for that collection.

Parental Consent: 
In the event HELIOS wishes to collect personal information from a child, we will first seek a parent's consent by email that then directs the parent to a series of web pages. In those pages, we will explain what information we are collecting, how we plan to use it, how the parent can provide consent, and how the parent can revoke consent. If we do not receive parental consent within a reasonable time, we will delete the parent's contact information and any other information collected from the child in connection with that activity.

This consent will take the form of 'high-level' verifiable consent, manifested by the parent's submission of the last four digits of his, her or their Social Security number or a credit or debit card number, in addition to basic identifying information of name, address, and date of birth.
  
By virtue of granting this consent, the parent is consenting to our collection and use (at all times in accordance with this Privacy Policy) of the child's personal information in connection with the child's use of HELIOS, including without limitation the following activities and features available on HELIOS:

Certain activities on our sites and applications allow children to create or manipulate content and save it with HELIOS. Some of these activities may allow a child to insert personal information in his or her created content and to share that content with other users. Examples of created content that may include personal information are images and videos. The parental consent allows this use of that personal information.

One of the features of HELIOS is a 'leaderboard', showing the names and points of players in various forms that are posted publicly on the app and shared with other users. The parental consent allows this use of that personal information where the child is one of the players.

For contests and challenges, we typically require only the information necessary for a child to participate, such as first name (to distinguish among family members) and parent email address (to notify the parent where required by law), but we may require more personalized information for prize-fulfillment purposes when the child wins the contest or challenge, and the parental consent allows this use. And if the contest or challenge asks the child to submit his or her own created content along with the child's entry, the parental consent permits this submission.

On occasion, in order to respond to a question or request from a child, HELIOS may need to ask for the child's online contact information, such as an email address. HELIOS will delete this information immediately after responding to the question or request. In connection with certain activities or services, we may collect a child's online contact information, such as an email address, in order to communicate with the child more than once. In such instances we will retain the child's online contact information to honor the request and for no other purpose such as marketing.  The parental consent permits this collection and retention of the child's information for these limited purposes.
 
Push notifications are notifications on mobile and other devices that are typically associated with downloaded applications, and which can communicate to the device holder even when the application is not in use. HELIOS utilizes push notifications, and the parental consent permits us to send push notifications from the Services to the child and to associate the device identifier with other personal information.

The parental consent permits the Services to collect geolocation information that in some instances may be specific enough to equate to the collection of a street address.

In the event we collect (or allow others to collect) information from children on our sites and applications using cookies and other tracking technologies for marketing purposes, the parental consent permits this collection.

With regard to school-based activities, COPPA allows teachers and school administrators to act in the stead of parents to provide consent for the collection of personal information from children. Schools should always notify parents about these activities. For more information on parental rights with respect to a child's educational record under the Family Educational Rights and Privacy Act (FERPA), please visit https://www2.ed.gov/policy/gen/guid/fpco/ferpa/index.html.

Sharing Children's Information: 
In addition to those rare instances where a child's personal information is posted publicly, we also may share or disclose personal information collected from a child in a limited number of instances, including the following:

We may share information with our service providers if necessary for them to perform a business, professional, or technology support function for us.

We may disclose personal information if permitted or required by law, for example, in response to a court order or a subpoena.
 
To the extent permitted by applicable law, we also may disclose personal information collected from children (i) in response to a law enforcement or public agency's (including schools or children services) request; (ii) if we believe disclosure may prevent the instigation of a crime, facilitate an investigation related to public safety or protect the safety of a child using our sites or applications; (iii) to protect the security or integrity of our sites, applications, and other technology, as well as the technology of our service providers; or (iv) enable us to take precautions against liability.

Parental Choices and Controls: 
At any time, parents can refuse to permit us to collect further personal information from their children in association with a particular account, and can request that we delete from our records the personal information we have collected in connection with that account. Please keep in mind that a request to delete records may lead to a termination of an account, membership, or other service.

Where a child has registered for a HELIOS account, we allow parents to access, change, or delete the personally identifiable information that we have collected from their children:

Parents can contact HELIOS to request access to, change, or delete their child's personal information by sending an email to us at privacy@helioshockey.com. A valid request to delete personal information will be accommodated within a reasonable time.

Any other inquiries may be directed to us in the manner described in the “How to Contact Us” section of this Privacy Policy. In any correspondence such as e-mail or mail, please include the child's username and the parent's email address and telephone number. To protect children's privacy and security, we will take reasonable steps to help verify a parent's identity before granting access to any personal information.



How We Use Personal Information

We use your personal information to improve, personalize, and provide Services to you. We may also use your personal information for the following purposes:

Service delivery:
To provide, operate, improve, develop, understand, and personalize the Services and our business, including testing, research, analysis and product development;
To satisfy the reason you provided the information to us, including responding to and fulfilling requests;
To communicate with you about the Services, including Service announcements, updates, or offers;
To provide support and assistance for the Services;
To create and manage your account or other user profiles;
To customize website content and communications based on your preferences; and
To process orders, memberships, or other transactions.

Social leaderboards:
To create a social community of HELIOS users who can connect, compete with and engage with each other.

Research and development:
To improve and develop the Services, including testing, research, and product development. As part of these activities, we create aggregated, de-identified or other anonymous data from personal information we collect. We make personal information into anonymous data by removing information that makes the data personally identifiable to you. We may use this anonymous data and share it with third parties for our lawful business purposes, including analyzing and improving the Services and promoting our business.

Marketing and advertising:
To contact you with HELIOS-related or other direct marketing communications as permitted by law. We may occasionally use third-party advertising partners to deliver our message. You may opt-out of our marketing communications as described in the Opt-out of marketing communications section below.

Compliance and protection:
To protect against or deter fraudulent, illegal, or harmful actions and maintain the safety, security, and integrity of our Services;
To comply with our legal or contractual obligations, resolve disputes, and enforce our Terms of Service;
To audit our internal processes for compliance with legal and contractual requirements and internal policies;
To protect our, your, or others' rights, privacy, safety, or property (including by making and defending legal claims); and
To respond to law enforcement requests and as required by applicable law, court order, or governmental regulations.

How We Share Personal Information

We may share your personal information with the following parties as otherwise described in this Privacy Policy:

Service providers: other companies or individuals we have authorized to use your personal information, for example customer support, hosting, analytics, and payment providers.

Advertising partners: other companies we have authorized to collect information on our website or mobile app through cookies or automatic collection.

Third-party platforms: social media platforms or other third-party platforms that you connect with HELIOS Services, including using platforms for logging into Services.

For business purposes: professional advisors such as lawyers, auditors, bankers, and insurers, where necessary in the course of performing normal professional services.

Authorities and others: law enforcement, government authorities, and private parties, when we believe in good faith it is legally required, necessary or appropriate to comply with law or legal process.

Business transferees: a third-party participant involved in a merger, acquisition, divestiture, consolidation, reorganization, bankruptcy, sale, or other disposition of all or any portion of the business or assets of, or equity interests in, HELIOS or our affiliates (including, in connection with a bankruptcy or similar proceedings).


Other Sites and Services

The Services may contain links to websites and other online services operated by third parties. These links and integrations are not an endorsement of, or representation that we are affiliated with, any third party. In addition, our content may be integrated into websites or other online services that are not associated with us. We do not control websites or other online services operated by third parties, and we are not responsible for their actions. We encourage you to review the privacy policies of the third party websites and online services to understand how these third parties use and share your personal information.

Security

We work hard to keep your information safe. HELIOS uses industry-standard technology (e.g. SSL) to protect your information from unauthorized access, use, or disclosure.

When you register for the Services, HELIOS requires you to create a password for your privacy and security. This password is stored in an encrypted manner on our systems. You should take reasonable measures to protect against unauthorized access to your passwords, limit access to your mobile device or computer, and sign out when you are not actively using the Services. 

While we aim to protect the privacy and security of your account and personal information, unfortunately no security measures are failsafe, and we cannot guarantee complete security of your personal information.

In the Event of Lost or Stolen Information

You must promptly notify us if you become aware that any information provided by or submitted to our Services is lost, stolen, or used without permission at privacy@helioshockey.com.

California Privacy Rights

This section applies only to additional rights for California residents. It describes how we collect, use and share personal information of California residents in operating our business, and their rights with respect to that personal information. For purposes of this section, “personal information” has the meaning given in the California Consumer Privacy Act of 2018 (“CCPA”) but does not include information exempted from the scope of the CCPA.

If you are a California resident, you have the following rights:

Notice: you can read the types of personal information we collect in the “Information We Collect” section of this Privacy Policy.

Access: you can request a full copy of the personal Information we have collected about you.

Deletion: you can also request that the personal information we have collected from you be deleted from our records.

Opt-out of Sale: we do not sell your personal information.

You are entitled to exercise the rights described above free from discrimination. 

To exercise your access or deletion rights, please email us at privacy@helioshockey.com. We may ask you to confirm your California residence and verify your identity prior to processing your request. You may designate an authorized agent to make a request on your behalf; however, we may require the authorized agent to present a signed written permission to act on your behalf and to verify your identity directly with us.


Your Rights

Access, update, delete: you can access your HELIOS account and review your personal information. You may email us at privacy@helioshockey.com or write us at the address below to request a full copy of your personal information for review. You can request that we correct any errors, outdated information, or omissions in the personal information that you have supplied to us. You can also request that your personal information be deleted from our records. Some information may remain in our records where legally allowed to maintain certain business records and some information (e.g. transaction data) may not be deleted. 

Privacy settings: you can change privacy settings in the Privacy section of the HELIOS mobile app settings.

Push notifications and device permissions: you can opt-out of push notifications that we send you or revoke any permissions you previously granted to HELIOS at any time, such as permission to access your camera or camera roll, Bluetooth, or push notifications, by changing the app settings on your mobile device.

Geolocation data: you can allow or disallow HELIOS to collect geolocation data by enabling or disabling location services in the settings on your mobile device.

Performance data: you may disable the collection of additional performance data by un-pairing your HELIOS device from your mobile device.

Marketing communication: you can opt-out of marketing emails from us by using the unsubscribe instructions in the communication. You cannot opt-out of receiving certain non-marketing emails that are necessary for the delivery of Services.

Online tracking: 
Browser Cookies: you can remove or reject cookies in most web browsers by following the instructions in the browser settings. Many browsers accept cookies by default until you change your settings. For more information about Cookies, including how to see what Cookies have been set on your device and how to manage and delete them, visit www.allaboutcookies.org. 

Do Not Track: your browser may have choices or settings that enable you to send “Do Not Track” signals to online services. Our Services do not currently support “Do Not Track” requests or similar signals. For more information about “Do Not Track”, please visit www.allaboutdnt.com.


How to Contact Us

If you have any questions about this Privacy Policy or how we handle the information you provide us, please write to us by email at privacy@helioshockey.com or by mail at:

Helios Sports, Inc.
Attn: Legal
348 Maplewood Ave, Unit 5
Portsmouth, NH 03801`}
        </p>
      </div>
    </>
  );
}

export default PrivacyPolicy;
