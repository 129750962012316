import { ThemeProvider } from '@emotion/react';
import { Alert, Box, Button, Checkbox, Container, Typography } from '@mui/material';
import React, { useState } from 'react';
import theme from '../theme';
import { CssBaseline } from '@mui/material';
import HeliosLogo from "../assets/images/helios_logo.png";
import * as qs from 'qs';
import axios from 'axios';

interface Props {};

enum Status {
  idle,
  pending,
  complete,
}

const ParentalConsent: React.FC<Props> = () => {
  const [agreeToTerms, setAgreeToTerms] = useState<boolean>(false);
  const [status, setStatus] = useState<Status>(Status.idle);

  const onSubmit = () => {
    setStatus(Status.pending);

    if (window.location.search.length) {
      const params = qs.parse(window.location.search.slice(1));
      const externalPayload = JSON.parse(params.externalPayload?.toString() || '{}');

      axios.post(`${process.env.REACT_APP_API_BASE_URL}/parental-consent-requests/parent-approval`, {
        parentalConsentRequestId: externalPayload.parentalConsentRequestId,
        profileId: externalPayload.profileId,
      })
      .then(function (response) {
        // handle success
        console.log(response);
        setStatus(Status.complete);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
        setStatus(Status.idle);
      })
    }
  }

  return (
    <>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <Container maxWidth="lg" sx={{paddingY: 5}}>
          <Box>
            <Box sx={{textAlign: 'center'}}>
              <img src={HeliosLogo} alt="HELIOS Logo" />
            </Box>
            <Typography variant="h4" gutterBottom={true}>Parental consent to use HELIOS:</Typography>
            <Typography variant="body1" paragraph={true}>Dear Parent or Guardian:</Typography>
            <Typography variant="body1" paragraph={true}>Your child under 13 has expressed a desire to access and use the HELIOS™ Core™ device and app, and our related website and other services (all collectively, the “Services”) - but before we allow this access and use, we require your consent to do so.  This web form asks for your consent and, because in the course of your child's access and use of the Services HELIOS will collect and use your child's personal information, we are providing you with specific information about our collection and use of that information so that your consent, if given, is informed.</Typography>
            <Typography variant="body1" paragraph={true}><strong>If we do not receive your consent within a reasonable time after the date of this email, we will delete your contact information and any information your child has provided us, and your child will not be permitted to use our Services.</strong></Typography>
            <Typography variant="h5" gutterBottom={true}>What Information does HELIOS Collect?</Typography>
            <Typography variant="body1" paragraph={true}>The information we collect on all users of the Services is described in detail in our Privacy Policy, found at <a target="_blank" href="https://helioshockey.com/privacy">https://helioshockey.com/privacy</a>. Briefly, this information includes information includes</Typography>
            <ul>
              <li>Personal identifiers, such as name and email address, HELIOS account credentials and profile information.</li>
              <li>Communications such as emails, messages, and notifications.</li>
              <li>Performance data, such as stride speed and active time, collected during the user's of the Services.</li>
              <li>Health data, such as heart rate.</li>
              <li>Information required to invoice the user for the Services and products purchased.</li>
              <li>Marketing data.</li>
              <li>Device data.</li>
              <li>Geolocation data.</li>
              <li>Online activity data.</li>
              <li>Photographic and videographic images and video of the user and others.</li>
            </ul>
            <Typography variant="body1" paragraph={true}>We also use cookies and other technologies to collect information from our users.</Typography>
            <Typography variant="h5" gutterBottom={true}>How Will HELIOS Use This Collected Information?</Typography>
            <Typography variant="body1" paragraph={true}>In general, we use this information to provide the Services and personalize them for your child, as a user of the Services, creating a community of users who can connect, compete with and engage one another; we also use it for research and development to improve the Services, for certain marketing purposes, for purposes of compliance with laws and law enforcement and with our own security policies, and to preserve and protect your privacy and our rights.</Typography>
            <Typography variant="body1" paragraph={true}>Specifically, we note the following use cases:</Typography>
            <ul>
              <li>Certain activities on our sites and applications allow your child to create or manipulate content and save it with HELIOS. Some of these activities may allow your child to insert personal information in his, her or their created content and to share that content with other users. Examples of created content that may include personal information are images and videos.</li>
              <li>One of the features of HELIOS is a 'leaderboard', showing the names and points of players, such as your child, in various forms that are posted publicly on the app and shared with other users.</li>
              <li>If your child participates in a contest or a challenge, he, she or they may be asked to submit created content along with the entry, and if your child wins the contest or challenge we may require more personalized information for prize-fulfillment purposes.</li>
              <li>On occasion, in order to respond to a question or request from your child, or to communicate more than once with your child, HELIOS may need to ask for your child's online contact information, such as an email address.</li>
              <li>HELIOS utilizes push notifications, which are sent to your child whether or not he, she or they are using the app, and which associate the device identifier with other personal information, and collects geolocation information that in some instances may be specific enough to equate to the collection of a street address.</li>
            </ul>
            <Typography variant="h5" gutterBottom={true}>How May You Give Your Consent?</Typography>
            <Typography variant="body1" paragraph={true}>We ask for your consent to collect and use your child's personal information in accordance with the terms of our Privacy Policy by means of a series of emails and web forms that ask (through our third party verification partner) for the last four digits of your Social Security number or a credit or debit card number, in addition to basic identifying information consisting of your name, address, and date of birth.  When your consent has been received and processed, your child will be able to access and use our Services.</Typography>
            <Typography variant="body1" paragraph={true}><strong>By virtue of granting this consent, you acknowledge and agree that you have read our Privacy Policy and that we may collect and use your child's personal information in connection with your child's use of HELIOS and our Services as set forth in our Privacy Policy.</strong></Typography>
            <Typography variant="h5" gutterBottom={true}>How May You Revoke Your Consent?</Typography>
            <Typography variant="body1" paragraph={true}>If at any time you wish to revoke your consent, or have any questions about our collection and use of your child's information, please write to us by email at <a href="mailto:privacy@helioshockey.com">privacy@helioshockey.com</a> or by mail at:</Typography>
            <Box sx={{textAlign: 'center'}}>
              <Typography
                variant="body1"
                paragraph={true}
                sx={{
                  display: 'inline-block',
                  textAlign: 'left',
                }}>
                Helios Sports, Inc.<br/>
                Attn: Legal<br/>
                348 Maplewood Ave, Unit 5<br/>
                Portsmouth, NH 03801
              </Typography>
            </Box>
            <Typography variant="body1" paragraph={true}>Thank you for choosing HELIOS, and we look forward to helping your child athlete improve in skill, effort and ability and to increasing your child's love of sports.</Typography>
            <Box sx={{textAlign: 'center'}}>
              <Box pb={2}>
                <Checkbox
                  value={agreeToTerms}
                  onChange={(e) => setAgreeToTerms(e.target.checked)}
                />
                I accept the HELIOS <a target="_blank" href="https://helioshockey.com/privacy">Privacy Policy</a> and <a target="_blank" href="https://helioshockey.com/terms">Terms of Service</a>
              </Box>
              <Box pb={2}>
                <Button variant="contained" disabled={!agreeToTerms || status !== Status.idle} onClick={onSubmit}>Grant Permission</Button>
              </Box>
              {status === Status.complete ? (
                <Alert severity="success">Thank you, you've successfully granted permission</Alert>
              ) : null}
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    </>
  )
}

export default ParentalConsent

