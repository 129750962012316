import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import './App.css';
import Train from './components/Train';
import TermsOfService from './components/TermsOfService';
import PrivacyPolicy from './components/PrivacyPolicy';
import ParentalConsent from './components/ParentalConsent';

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/train">
          <Train />
        </Route>
        <Route path="/terms-of-service">
          <TermsOfService />
        </Route>
        <Route path="/privacy-policy">
          <PrivacyPolicy />
        </Route>
        <Route path="/parental-consent">
          <ParentalConsent />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
