import React, { useState } from "react";
import FlocklerEmbed from "./FlocklerEmbed";

// import TrainingMindfulnessHero from "../assets/images/training_mindfulness_hero.jpg";
// import TrainingShootingHero from "../assets/images/training_shooting_hero.jpg";
import TrainingSkatingHero from "../assets/images/training_skating_hero.jpg";
// import TrainingStickhandlingHero from "../assets/images/training_stickhandling_hero.jpg";

function Train() {
  const [sectionId, setSectionId] = useState<number | null>(null);

  const sections: any[] = [
    // {
    //   image: TrainingMindfulnessHero,
    //   title: "Mindfulness",
    //   id: 48700
    // },
    {
      image: TrainingSkatingHero,
      title: "Skating",
      id: 48749
    },
    // {
    //   image: TrainingShootingHero,
    //   title: "Shooting",
    //   id: 48897
    // },
    // {
    //   image: TrainingStickhandlingHero,
    //   title: "Stickhandling",
    //   id: 48898
    // }
  ];

  return (
    <>
      <script src="https://embed-cdn.flockler.com/embed-v2.js"></script>
      <style>
        {`body {
          overflow: hidden;
          background: transparent;
        }`}
      </style>
      <div>
        {sectionId && (
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
          }}>
            <div
              onClick={() => setSectionId(null)}
              style={{
                display: 'flex',
                height: 40,
                width: 40,
                marginRight: 6,
                marginBottom: 15,
                border: 'solid 2px #fff',
                color: '#fff',
                fontSize: 30,
                justifyContent: 'center',
                alignItems: 'center',
                fontWeight: 700,
              }}>
              &times;
            </div>
          </div>
        )}
        {!sectionId && <>
          {sections.map((section, index) => (
            <div
              key={index}
              onClick={() => setSectionId(section.id)}
              style={{
                position: 'relative',
                marginBottom: 12,
              }}>
              <img src={section.image} style={{width: '100%'}} alt={section.title} />
              <h2 style={{
                position: 'absolute',
                top: 10,
                bottom: 10,
                left: 10,
                right: 10,
                color: '#fff',
                fontFamily: 'Poppins',
                fontWeight: 500,
                textTransform: 'uppercase',
                marginTop: 0,
              }}>{section.title}</h2>
            </div>
          ))}
        </>}
        {sectionId && <FlocklerEmbed siteId={14789} sectionId={sectionId} />}
      </div>
    </>
  );
}

export default Train;