import React from "react";

declare const window: any;

interface Props {
  siteId: any;
  sectionId: any;
}

class FlocklerEmbed extends React.Component<Props> {
  constructor(props: any) {
    super(props);
    this.state = {
      flocklerEmbed: null
    };
  }

  state: any = {
    flEmbed: null,
  }

  componentDidMount() {
    if (window.flcklr) {
      const flocklerEmbed = window.flcklr.Embeds.create({
        container: `flockler_container--${this.props.siteId}`,
        site: this.props.siteId,
        section: this.props.sectionId,
        count: 20,
        refresh: 0,
        style: "wall_v1"
      });

      this.setState({
        flocklerEmbed: flocklerEmbed
      });
    }
  }

  componentWillUnmount() {
    if (this.state.flEmbed) {
      this.state.flEmbed.destroy();
    }

    this.setState({
      flocklerEmbed: null
    });
  }

  render() {
    return (
      <>
        <div id={`flockler_container--${this.props.siteId}`} />
      </>
    );
  }
}

export default FlocklerEmbed;
